<template>
 <div class="content-box">
        <div class="container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>资料管理</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="button-gutter">
      <el-button style="width: 96px" type="primary" @click="AddProduct"
        >新增产品</el-button
      >
    </div>

    <el-table :data="tableData" border max-height="600">
      <el-table-column prop="name" align="center" width="100" label="产品名称">
      </el-table-column>
      <el-table-column
        prop="ratedPower"
        align="center"
        width="100"
        label="额定功率"
      >
      </el-table-column>
      <el-table-column prop="windSpeed" align="center" label="切入风速">
      </el-table-column>
      <el-table-column
        prop="generatingGrade"
        align="center"
        label="设计风区等级"
      >
      </el-table-column>
      <el-table-column
        prop="cutOutSpeed"
        align="center"
        label="切出风速（10分钟均值）"
      >
      </el-table-column>
      <el-table-column
        prop="unitOperationTemperature"
        align="center"
        width="100"
        label="机组运行温度(低/常)"
      >
      </el-table-column>
      <el-table-column
        prop="unitSurvivalTemperature"
        align="center"
        width="100"
        label="机组生存温度(低/常)"
      >
      </el-table-column>
      <el-table-column
        prop="designServiceLife"
        align="center"
        width="100"
        label="设计使用寿命"
      >
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button 
                size="mini"
          type="primary"
          plain
          style="margin-right: 12px" @click="Reset(scope.row)">
            编辑
          </el-button>
          <el-popconfirm
            confirm-button-text="确定"
            cancel-button-text="取消"
            confirm-button-type="danger"
            icon="el-icon-info"
            icon-color="red"
            title="确定删除吗？"
            @confirm="deleteFile(scope.row, scope.$index)"
          >
            <el-button type="danger"      size="mini"
      
          plain  slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-paging">
      <el_pagination
        :total="total"
        :num="num"
        @page-limit="page_limit"
      ></el_pagination>
    </div>
    <el-dialog
      title="新增产品"
      :visible.sync="dialogVisible"
      :before-close="Cancel"
            width="800px"
    >
      <el-form
        ref="elForm"
        :model="productFrom"
        label-width="120px"
        label-position="left"
      >
        <el-form-item label="产品名称" required>
          <el-input v-model="productFrom.name" placeholder="请输入产品名称" />
        </el-form-item>
        <el-form-item label="额定功率" required>
          <el-input
            v-model="productFrom.ratedPower"
            placeholder="请输入额定功率"
          />
        </el-form-item>
        <el-form-item label="切入风速" required>
          <el-input
            v-model="productFrom.windSpeed"
            placeholder="请输入切入风速"
          />
        </el-form-item>
        <el-form-item label="设计风区等级" required>
          <el-input
            v-model="productFrom.generatingGrade"
            placeholder="请输入设计风区等级"
          />
        </el-form-item>
        <el-form-item label="切出风速（10分钟均值）" required>
          <el-input
            v-model="productFrom.cutOutSpeed"
            placeholder="请输入切出风速（10分钟均值）"
          />
        </el-form-item>
        <el-form-item label="机组运行温度（低/常）" required>
          <el-input
            v-model="productFrom.unitOperationTemperature"
            placeholder="请输入机组运行温度（低/常）"
          />
        </el-form-item>
        <el-form-item label="机组生存温度（低/常）" required>
          <el-input
            v-model="productFrom.unitSurvivalTemperature"
            placeholder="请输入机组生存温度（低/常）"
          />
        </el-form-item>
        <el-form-item label="设计使用寿命" required>
          <el-input
            v-model="productFrom.designServiceLife"
            placeholder="请输入设计使用寿命"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="footer">
        <el-button @click="Cancel">取 消</el-button>
        <el-button type="primary" @click="SendData(productFrom)"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑产品 -->

    <el-dialog
      title="编辑产品"
      :visible.sync="resetDialogVisible"
      :before-close="Cancel"
      width="800px"
    >
      <el-form
        ref="elForm"
        :model="resetProductFrom"
        label-width="120px"
        label-position="left"
      >
        <el-form-item label="产品名称" required>
          <el-input
            v-model="resetProductFrom.name"
            placeholder="请输入产品名称"
          />
        </el-form-item>
        <el-form-item label="额定功率" required>
          <el-input
            v-model="resetProductFrom.ratedPower"
            placeholder="请输入额定功率"
          />
        </el-form-item>
        <el-form-item label="切入风速" required>
          <el-input
            v-model="resetProductFrom.windSpeed"
            placeholder="请输入切入风速"
          />
        </el-form-item>
        <el-form-item label="设计风区等级" required>
          <el-input
            v-model="resetProductFrom.generatingGrade"
            placeholder="请输入设计风区等级"
          />
        </el-form-item>
        <el-form-item label="切出风速（10分钟均值）" required>
          <el-input
            v-model="resetProductFrom.cutOutSpeed"
            placeholder="请输入切出风速（10分钟均值）"
          />
        </el-form-item>
        <el-form-item label="机组运行温度（低/常）" required>
          <el-input
            v-model="resetProductFrom.unitOperationTemperature"
            placeholder="请输入机组运行温度（低/常）"
          />
        </el-form-item>
        <el-form-item label="机组生存温度（低/常）" required>
          <el-input
            v-model="resetProductFrom.unitSurvivalTemperature"
            placeholder="请输入机组生存温度（低/常）"
          />
        </el-form-item>
        <el-form-item label="设计使用寿命" required>
          <el-input
            v-model="resetProductFrom.designServiceLife"
            placeholder="请输入设计使用寿命"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="footer">
        <el-button @click="Cancel">取 消</el-button>
        <el-button type="primary" @click="ResetData(resetProductFrom)"
          >确 定</el-button
        >
      </div>
    </el-dialog>
 </div>
  </div>
</template>

<script>
import {
  documentList,
  deleteProductList,
  productAdd,
  productList,
  productUpdate,
} from "@/api/document";
import {} from "@/api/product";
export default {
  name: "",

  data() {
    return {
      dialogVisible: false,
      resetDialogVisible: false,
      tableData: [], // 修改前接口后台获取数据
      resetProductFrom: {},
      productFrom: {},
      fileList: [],
      num: 0,
      pageSize: 10,
      currentPage: 1,
      total: 0,
    };
  },
  created() {
    this.getFileList();
  },
  methods: {
    // 分页子集传过来的数据  page = 页码  limit = 条数
    // 分页子集传过来的数据  page = 页码  limit = 条数
    page_limit(list) {
      console.log(list);
      let { page, limit } = list;
      this.currentPage = page;
      this.pageSize = limit;
      this.tableData = [];
      this.getFileList();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    // 获取产品表
    async getFileList() {
      let limit = this.pageSize;
      let page = this.currentPage;
      let res = await productList({
        limit,
        page,
      });
      if (res.code == 200) {
        this.tableData = res.data;
        console.log(res.data);
        this.total = res.total;
        this.num = parseInt(res.total / limit) + 1;
      }
    },
    //  删除 产品
    async deleteFile(row) {
      let id = row.id;

      const res = await deleteProductList({
        id,
      });
      if (res.code === 200) {
        this.$message.success("删除成功");
        this.getFileList();
      } else {
        this.$message.error("删除失败");
      }
      await this.getFileList();
    },
    // 新增弹框
    AddProduct() {
      this.dialogVisible = true;
    },
    //确定新增
    async SendData(productFrom) {
      let res = await productAdd(productFrom);
      if (res.code === 200) {
        this.$message.success("新增成功");
        this.productFrom = {};
        this.dialogVisible = false;
        this.getFileList();
      } else {
        this.$message.error("新增失败");
        this.productFrom = {};
        this.dialogVisible = false;
      }
    },
    //取消
    Cancel() {
      this.productFrom = {};
      this.dialogVisible = false;
      this.resetDialogVisible = false;
    },

    //编辑弹框
    Reset(row) {
      this.resetProductFrom =JSON.parse(JSON.stringify(row))
      // this.resetProductFrom.name = row.name;
      // this.resetProductFrom.ratedPower = row.ratedPower;
      // this.resetProductFrom.windSpeed = row.windSpeed;
      // this.resetProductFrom.generatingGrade = row.generatingGrade;
      // this.resetProductFrom.cutOutSpeed = row.cutOutSpeed;
      // this.resetProductFrom.unitOperationTemperature = row.unitOperationTemperature;
      // this.resetProductFrom.unitSurvivalTemperature = row.unitSurvivalTemperature;
      // this.resetProductFrom.designServiceLife = row.designServiceLife;
    
      this.resetDialogVisible = true;
    },

    async ResetData(resetProductFrom) {
      console.log(resetProductFrom);
      let res = await productUpdate(resetProductFrom);
      if (res.code === 200) {
        this.$message.success("编辑成功");
        this.resetProductFrom = {};
        this.resetDialogVisible = false;
        this.getFileList();
      } else {
        this.$message.error("编辑失败");
        this.resetProductFrom = {};
        this.resetDialogVisible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-gutter {
  margin: 20px 0;
}
.tests {
  margin: -40px 0px 0px 120px;
}
.table-paging {
  right: 60px;
  position: absolute;
}
::v-deep .el-dialog__header {
  text-align: center;
}
.footer {
  width: 30%;

  margin: 0 auto;
}
.container{
  height: 100%;
}
</style>
